<template>
  <ag-grid-vue
    v-if="rowData"
    :class="[
      $vuetify.theme.dark ? agThemeDark : agThemeLight,
      pinHeader ? 'pin-header' : ''
    ]"
    :column-defs="columnDefs"
    :default-col-def="defaultColDef"
    :detailCellRendererParams="
      $vuetify.breakpoint.smAndDown
        ? detailCellRendererParamsMo
        : detailCellRendererParams
    "
    :enableCellChangeFlash="true"
    :getRowId="getRowId"
    :keepDetailRows="true"
    :keepDetailRowsCount="3"
    :masterDetail="true"
    :modules="modules"
    :pagination="isPaged"
    :pagination-page-size="pageSize"
    :paginationPageSizeSelector="[10, 20, 40]"
    :row-data="rowData"
    class="pb-3 pr-3 pl-3"
    dom-layout="autoHeight"
    style="width: 100%; height: 100%;"
    tooltip-show-delay="500"
    @grid-ready="onGridReady"
    @pagination-changed="paginationChanged"
    @first-data-rendered="onFirstDataRendered"
  />
  <v-row
    v-else
    align="center"
    class="btn-row no-staff pl-3 pt-3 pb-3 pr-3"
    justify="center"
  >
    <span>No staff found</span>
  </v-row>
</template>

<script>
import { AgGridVue } from "@ag-grid-community/vue";
import { AG_ALPINE_THEME, PAGE_FORMATS } from "@/utils/constants";
import staffApi from "api/staff";
import store from "../store/";
import { computed, onBeforeMount, onBeforeUnmount, ref, watch } from "vue";

export default {
  props: {
    rowData: {
      type: Array,
      required: true
    },
    pageFormat: {
      type: String,
      default: PAGE_FORMATS.multi
    },
    pageSize: {
      type: Number,
      required: true
    },
    modules: {
      type: Array,
      required: true
    },
    columnDefs: {
      type: Array,
      required: true
    },
    pinHeader: {
      type: Boolean,
      default: true
    }
  },
  components: { AgGridVue },
  setup(props) {
    const agThemeDark = AG_ALPINE_THEME.dark;
    const agThemeLight = AG_ALPINE_THEME.light;
    const multiPagesType = PAGE_FORMATS.multi;
    const isPaged = computed(() => props.pageFormat === PAGE_FORMATS.multi);
    let attCu = 0;
    const gridApi = ref();
    const getRowId = ref(null);
    const attRowId = computed(() => store.getters.attRowIdUpdate);
    const onGridReady = params => {
      gridApi.value = params.api;
    };
    const paginationChanged = params => {
      const proxy = params.api.paginationProxy;
      if (proxy.active) {
        attCu = proxy.currentPage;
      }
    };
    const onFirstDataRendered = params => {
      if (isPaged) {
        const attCurrentPage = store.getters.attCurrentPage;
        params.api.paginationGoToPage(attCurrentPage);
      }
    };
    const defaultColDef = {
      editable: false,
      sortable: true,
      filter: true,
      flex: 1,
      resizable: true,
      autoHeight: true,
      minWidth: 190,
      wrapText: false,
      wrapHeaderText: true,
      autoHeaderHeight: true
    };
    const detailCellRendererParams = {
      refreshStrategy: "rows",
      detailGridOptions: {
        enableCellChangeFlash: true,
        pagination: false,
        paginationAutoPageSize: true,
        columnDefs: [
          { field: "applicationName", maxWidth: 180 },
          { field: "applicationCode", maxWidth: 180 },
          { field: "applicationDesc", headerName: "Application Description" },
          { field: "tasksProvisioned" }
        ],
        defaultColDef: {
          flex: 1
        }
      },
      getDetailRowData: async params => {
        //DEBUG locType here
        //console.log("params.data from Attestation table: "+JSON.stringify(params.data));
        const locationId = params.data.locationId;

        //console.log("locationId from Attestation table: "+locationId);
        //console.log("full locations from Attestation table: "+JSON.stringify(store.getters.locations));

        const locType = store.getters.locations.find(l => l.id === String(locationId)).locType;
        const staffId = params.data.detUserId;
        const netRegId = params.data.netRegId;
        //console.log("Before calling getAppsForStaffAtLocation from Attestation: locType-"+locType);
        await staffApi
          .getAppsForStaffAtLocation(locationId, locType, staffId, netRegId)
          .then(response => {
            return store.commit("setApps", {
              locationId,
              staffId,
              apps: response.applications,
              roleCode: response.roleCode
            });
          });
        store.commit("setReloadApplicationsRequired", false);
        const apps = store.getters.applications(locationId, staffId);
        const accessedApps = apps
          .filter(app => app.tasks.some(task => task.hasAccess))
          .map(app => {
            app.tasksProvisioned = app.tasks
              .filter(task => task.hasAccess)
              .map(task => task.taskName)
              .join(", ");
            return app;
          });
        params.successCallback(accessedApps);
      }
    };
    const detailCellRendererParamsMo = {
      ...detailCellRendererParams,
      detailGridOptions: {
        pagination: false,
        paginationAutoPageSize: true,
        columnDefs: [
          { field: "applicationName", minWidth: 170 },
          { field: "tasksProvisioned", minWidth: 370 }
        ],
        defaultColDef: {
          flex: 1
        }
      }
    };
    watch(attRowId, detUserId => {
      if (!detUserId) return;
      const rowNode = gridApi.value.getRowNode(detUserId);
      const dataForRow = props.rowData.find(row => row.detUserId === detUserId);
      rowNode.updateData(dataForRow);
      store.commit("setUpdateRowId", "");
    });
    onBeforeMount(() => {
      getRowId.value = params => params.data.detUserId;
    });
    onBeforeUnmount(() => {
      store.commit("setAttCurrentPage", attCu);
    });

    return {
      agThemeDark,
      agThemeLight,
      multiPagesType,
      isPaged,
      defaultColDef,
      detailCellRendererParams,
      detailCellRendererParamsMo,
      paginationChanged,
      onFirstDataRendered,
      onGridReady,
      getRowId,
      gridApi
    };
  }
};
</script>

<style lang="scss" scoped></style>
