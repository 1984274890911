<script>
import { DIALOG_ACTIONS, MSA_CLIENT_ERROR } from "@/utils/constants";
import staffApi from "@/api/staff";
import { mapGetters } from "vuex";
import Dialog from "@/components/Dialog";
import capitalise from "@/filters/capitalise";

export default {
  data() {
    return {
      dialogActions: DIALOG_ACTIONS,
      rowNode: null
    };
  },
  components: {
    Dialog
  },
  computed: {
    ...mapGetters({
      locationId: "selectedSchoolId"
    }),
    isAttesting() {
      return this.$store.getters.isStaffInAttesting(this.params.data.detUserId);
    }
  },
  created() {
    this.rowNode = this.params.node;
  },
  methods: {
    async confirmAttestation(e) {
      e.preventDefault();
      const action = staffApi.confirmStaffAnnualAttestationForLocation;
      this.attAction(action);
    },
    async removeAttestation(e) {
      e.preventDefault();
      this.$refs.attestationRemove.open();
    },
    updateAtt() {
      const self = this;
      const detUserId = self.params.data.detUserId;
      const path = `provision/staff-view/${detUserId}/attestation`;
      this.$router.push({ path: path });
    },
    updateRowData(newData) {
      this.rowNode.updateData(newData);
    },
    async attAction(action) {
      const self = this;
      const netRegId = self.params.value;
      const detUserId = self.params.data.detUserId;
      if (!netRegId) {
        return this.$store.commit("showError", {
          show: true,
          message: MSA_CLIENT_ERROR.netRegIdMissing
        });
      }
      this.$store.commit("addStaffToAttesting", detUserId);
      try {
        const res = await action(this.locationId, netRegId);
        const newRow = res.staffAttestation;
        newRow.fullName = `${newRow.firstname} ${newRow.lastname}`;
        newRow.staffRoleFormatted = capitalise(
          (newRow.role || "")
            .toLowerCase()
            .split(".")
            .join(" ")
        );
        const staffAnnualAttestation = this.$store.state.staffModule
          .staffAnnualAttestationByLocation[this.locationId];
        staffAnnualAttestation.map(s => {
          if (s.detUserId === newRow.detUserId) {
            s.attestationCompleted = newRow.attestationCompleted;
            s.attestedBy = newRow.attestedBy;
            s.attestationDate = newRow.attestationDate;
            s.actionTaken = newRow.actionTaken;
            s.role = newRow.role;
            s.staffRoleFormatted = newRow.staffRoleFormatted;
          }
        });
        this.$store.commit("setStaffAnnualAttestation", {
          locationId: this.locationId,
          staffAnnualAttestation
        });
        this.$store.commit("removeStaffFromAttesting", detUserId);
        this.$store.commit("setUpdateRowId", detUserId);
      } catch (e) {
        this.$store.commit("removeStaffFromAttesting", detUserId);
      }
    },
    removeAccessConfirm(selection) {
      if (selection === "Yes") {
        const action = staffApi.removeStaffAnnualAttestationForLocation;
        this.attAction(action);
      }
    }
  }
};
</script>

<template>
  <div v-if="!params.data.attestationCompleted" class="ml-1 mr-1 mb-1">
    <v-progress-circular
      v-if="isAttesting"
      color="primary"
      indeterminate
      mr-3
      size="24"
      width="3"
    />
    <div v-else>
      <v-btn
        aria-label="confirm"
        class="icon-btn"
        icon
        @click="confirmAttestation"
      >
        <v-icon color="#00AA45">mdi-account-check-outline</v-icon>
      </v-btn>
      <v-btn
        aria-label="remove"
        class="icon-btn"
        icon
        @click="removeAttestation"
      >
        <v-icon color="#D7153A">mdi-account-remove-outline</v-icon>
      </v-btn>
      <v-btn aria-label="modify" class="icon-btn" icon @click="updateAtt">
        <v-icon color="#407EC9">mdi-account-edit-outline</v-icon>
      </v-btn>
    </div>
    <Dialog
      ref="attestationRemove"
      :actions="dialogActions"
      :callback="removeAccessConfirm"
      :message="
        `Are you sure you want to remove all access from ${params.data.fullName}?`
      "
      title="Remove access"
      type="warning"
    />
  </div>
</template>

<style lang="scss" scoped>
.icon-btn {
  border-radius: 4px;
  background: #cbedfd;
  width: 30px;
  height: 30px;
  margin: 0 0.35rem;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.3), 0 4px 8px 3px rgba(0, 0, 0, 0.15);
}
</style>
