<script>
import AttestationProgress from "@/components/AttestationProgress.vue";
import { mapGetters } from "vuex";
import {
  PAGE_FORMATS,
  PAGE_FORMATS_OBJ,
  STAFF_TYPES,
  STAFF_TYPES_OBJ,
  UI_ACTIONS,
  VUE_APP_AG_GRID_ENTERPRISE
} from "@/utils/constants";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { ColumnsToolPanelModule } from "@ag-grid-enterprise/column-tool-panel";
import { ExcelExportModule } from "@ag-grid-enterprise/excel-export";
import { MenuModule } from "@ag-grid-enterprise/menu";
import { SetFilterModule } from "@ag-grid-enterprise/set-filter";
import { MasterDetailModule } from "@ag-grid-enterprise/master-detail";
import { getConfig } from "@/utils/config";
import AttestationTable from "@/components/AttestationTable.vue";
import { computed, getCurrentInstance, ref, watch } from "vue";
import store from "@/store";
import GridTableControls from "@/components/GridTableControls.vue";
import AttestationActions from "@/components/AttestationActions.vue";
import router from "@/router";

export function useVuetify() {
  const vm = getCurrentInstance();
  return vm.proxy?.$vuetify || undefined;
}

export default {
  components: {
    GridTableControls,
    AttestationTable,
    AttestationProgress,
    // eslint-disable-next-line vue/no-unused-components
    attestationActions: AttestationActions
  },
  setup() {
    const useAgGridEnterprise =
      getConfig(VUE_APP_AG_GRID_ENTERPRISE) === "true";
    const pageFormats = PAGE_FORMATS_OBJ;
    const staffTypes = STAFF_TYPES_OBJ;
    const externalStaff = STAFF_TYPES.external;
    const multiPagesType = PAGE_FORMATS.multi;
    const uIActions = UI_ACTIONS;
    const tblKey = ref(0);
    const formatDate = params => {
      if (!params.value) return "";
      const options = { year: "numeric", month: "short", day: "numeric" };
      return new Date(params.value).toLocaleDateString("en-US", options);
    };
    const commonColumns = [
      {
        field: "fullName",
        headerName: "Staff",
        pinned: "left",
        sort: "asc",
        sortIndex: 0,
        cellRenderer: "agGroupCellRenderer",
        cellClass: "att-staff"
      },
      { field: "staffRoleFormatted", headerName: "Role" },
      { field: "actionTaken", maxWidth: 200 },
      {
        field: "attestationDate",
        valueFormatter: formatDate,
        maxWidth: 200,
        cellClass: params => {
          return params.data.attestationCompleted ? "att-done" : "att-todo";
        }
      },
      { field: "attestedBy" },
      {
        field: "attestationCompleted",
        headerName: "Attestation Status",
        maxWidth: 200
      }
    ];
    const actionColumn = {
      field: "netRegId",
      headerName: "Actions",
      maxWidth: 200,
      headerTooltip: "Attestation actions: confirm, remove or modify",
      cellRenderer: "attestationActions"
    };
    const columnDefs = [...commonColumns, actionColumn];
    const columnDefsMo = [actionColumn, ...commonColumns];
    const modules = useAgGridEnterprise
      ? [
          ClientSideRowModelModule,
          ColumnsToolPanelModule,
          ExcelExportModule,
          MenuModule,
          SetFilterModule,
          MasterDetailModule
        ]
      : [ClientSideRowModelModule];
    const locationId = computed(() => store.getters.selectedSchoolId);
    const isGovSchool = computed(
      () =>
        store.state.locationsModule.locations.find(
          l => l.id === locationId.value
        ).isGov
    );
    const pageFormat = computed(() => store.getters.pageFormat);
    const pageSize = computed(() => store.getters.pageSize);
    const attestationInfo = computed(() => {
      const isExternal = store.getters.staffType === externalStaff;
      return (
        store.state.staffModule.staffAnnualAttestationByLocation[
          locationId.value
        ]?.filter(staff => staff.isExternal === isExternal) ?? []
      );
    });
    const attWindowStatus = computed(
      () => store.getters.attestationWindowStatus
    );
    const attColDefs = computed(() => {
      const vuetify = useVuetify();
      if (attWindowStatus.value === "Open") {
        return vuetify.breakpoint.mobile ? columnDefsMo : columnDefs;
      }
      return commonColumns;
    });
    const progress = computed(() => {
      const all =
        store.state.staffModule.staffAnnualAttestationByLocation[
          locationId.value
        ] ?? [];
      const allL = all.length === 0 ? 1 : all.length;
      const cmd = all?.filter(a => a.attestationCompleted === true) ?? [];
      return parseInt((cmd.length / allL) * 100);
    });
    watch(
      locationId,
      l => {
        if (l && isGovSchool.value) {
          return store.dispatch("fetchStaffAnnualAttestationForLocation", {
            locationId: l
          });
        }
        router.push({ name: "staff-view" });
      },
      { immediate: true }
    );
    watch(pageFormat, format => {
      if (format === multiPagesType) {
        tblKey.value += 1;
      }
    });
    return {
      pageFormats,
      pageFormat,
      pageSize,
      staffTypes,
      externalStaff,
      multiPagesType,
      uIActions,
      modules,
      isGovSchool,
      attestationInfo,
      progress,
      tblKey,
      attWindowStatus,
      dynamicCloDefs: attColDefs
    };
  },
  computed: {
    ...mapGetters({
      isLoading: "isLoading",
      fnActionByName: "getActionByName",
      staffType: "staffType"
    })
  }
};
</script>

<template>
  <v-container
    v-if="isGovSchool"
    :class="{
      mobile: $vuetify.breakpoint.smAndDown,
      page: $vuetify.breakpoint.smAndDown
    }"
  >
    <v-card class="msa-list mt-6">
      <AttestationProgress
        v-if="attWindowStatus === 'Open'"
        :progress="progress"
      />
      <p v-else class="pa-4 subtitle-1">
        The annual attestation cycle runs between November and February each year.
        You will receive an email once the attestation window is open.
      </p>
    </v-card>
    <div v-if="!isLoading">
      <v-card class="msa-list mt-6">
        <section class="d-flex flex-column">
          <GridTableControls :is-gov="isGovSchool" class="ma-2" />
          <attestation-table
            :key="tblKey"
            :column-defs="dynamicCloDefs"
            :modules="modules"
            :page-format="pageFormat"
            :page-size="pageSize"
            :pin-header="!$vuetify.breakpoint.smAndDown"
            :row-data="attestationInfo"
          />
        </section>
      </v-card>
    </div>
  </v-container>
  <h3 v-else class="ma-8">Feature not applicable to the school selected.</h3>
</template>

<style lang="scss" scoped></style>
