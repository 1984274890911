<template>
  <div class="aa-progress">
    <p class="subtitle-2">Attestation progress ({{ progress }}%):</p>
    <v-progress-linear
      :rounded="true"
      :rounded-bar="true"
      :value="progress"
      color="#00AA45"
      height="12"
    />
  </div>
</template>

<script>
export default {
  props: {
    progress: {
      type: Number,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
.aa-progress {
  display: flex;
  align-items: center;
  padding: 1rem;
  margin: 1rem 0;
  border: 1px solid #cdd3d6;
  border-radius: 0.25rem;
  gap: 1rem;

  p {
    flex-basis: 40%;
    margin: 0;
  }
}

@media (max-width: 767px) {
  .aa-progress {
    margin: 1rem 0.5rem;
    flex-direction: column;
    align-items: flex-start;
  }
}
</style>
